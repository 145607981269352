import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import SkeletonLoader from './styles/SkeletonLoader';

// Lazy loaded components
const StudentRoutes = lazy(() => import('./student/StudentRoutes'));
const InstituteRoutes = lazy(() => import('./institute/InstituteRoutes'));
const AdminRoutes = lazy(() => import('./Admin/AdminRoutes'));
const GeneralRoutes = lazy(() => import('./components/GeneralRoutes'));

function App() {
  return (
    <Router>
      <AppWrapper>
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            {/* Protected Routes */}
            <Route path="/student/*" element={<StudentRoutes />} />
            <Route path="/institute/*" element={<InstituteRoutes />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            
            {/* Public Routes */}
            <Route path="/*" element={<GeneralRoutes />} />
          </Routes>
        </Suspense>
      </AppWrapper>
    </Router>
  );
}

export default App;