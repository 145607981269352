import React, { createContext, useContext, useState, useEffect } from 'react';
import instituteApi, { getStoredToken, isTokenValid } from './services/InstituteApiService';

const InstituteAuthContext = createContext(null);

export const InstituteAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [institute, setInstitute] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const token = getStoredToken();
        if (token && isTokenValid()) {
          console.log('Verifying stored token on app start');
          const response = await instituteApi.get('/institute/verify-token');
          setInstitute(response.data.institute);
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setInstitute(null);
      } finally {
        setLoading(false);
      }
    };

    verifyAuth();
  }, []);

  const login = async (token, institute) => {
    try {
      console.log('Auth Provider: Login called with token and institute data');
      
      if (token) {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setInstitute(institute);
        console.log('Auth Provider: Authentication successful');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Auth Provider: Login failed:', error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('instituteId');
    localStorage.removeItem('instituteName');
    setIsAuthenticated(false);
    setInstitute(null);
  };

  const value = {
    isAuthenticated,
    institute,
    login,
    logout,
    loading
  };

  return (
    <InstituteAuthContext.Provider value={value}>
      {!loading && children}
    </InstituteAuthContext.Provider>
  );
};

export const useInstituteAuth = () => {
  const context = useContext(InstituteAuthContext);
  if (!context) {
    throw new Error('useInstituteAuth must be used within an InstituteAuthProvider');
  }
  return context;
};