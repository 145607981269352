import axios from 'axios';

const instituteApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Update the request interceptor to properly handle the token
instituteApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    console.log('InstituteAPI Request Config:', {
      url: config.url,
      method: config.method,
      hasToken: !!token,
      tokenPreview: token ? `${token.substring(0, 20)}...` : 'None',
      isFormData: config.data instanceof FormData
    });

    if (token) {
      config.headers.Authorization = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
instituteApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    });
    return Promise.reject(error);
  }
);

// Token utility functions
const getStoredToken = () => {
  const token = localStorage.getItem('token');
  console.log('Getting stored token:', {
    exists: !!token,
    preview: token ? `${token.substring(0, 20)}...` : 'None'
  });
  return token;
};

const isTokenValid = () => {
  const token = getStoredToken();
  if (!token) return false;
  
  try {
    // Basic check if token is a JWT
    const parts = token.split('.');
    return parts.length === 3;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};

// Single export statement for all exports
export { getStoredToken, isTokenValid };
export default instituteApi; 