import React from 'react';

const HapusLogo = () => (
  <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" fill="#F16001"/>
    <text x="10" y="35" fontFamily="Arial, sans-serif" fontSize="30" fontWeight="bold" fill="white">
      <tspan fontSize="35">H</tspan><tspan fontSize="30">apus</tspan>
    </text>
  </svg>
);

export default HapusLogo;