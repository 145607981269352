import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaHome, FaInfoCircle, FaBook, FaEnvelope, FaUserGraduate, FaUniversity, FaSignOutAlt, FaTachometerAlt, FaUser, FaBlog } from 'react-icons/fa';
import HapusLogo from './HapusLogo';
import { useStudentAuth } from '../student/StudentAuthProvider';
import { useInstituteAuth } from '../institute/InstituteAuthProvider';

const CustomNavbar = () => {
  const navigate = useNavigate();
  const { isAuthenticated: isStudentAuthenticated, logout: studentLogout } = useStudentAuth();
  const { isAuthenticated: isInstituteAuthenticated, logout: instituteLogout } = useInstituteAuth();

  const handleLogout = () => {
    if (isStudentAuthenticated) {
      studentLogout();
    } else if (isInstituteAuthenticated) {
      instituteLogout();
    }
    navigate('/');
  };

  return (
    <Navbar expand="lg" className="hapus-navbar">
      <Container className="hapus-nav-container">
        <Navbar.Brand as={Link} to="/" className="hapus-brand">
          <HapusLogo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="hapus-nav-toggle" />
        <Navbar.Collapse id="basic-navbar-nav" className="hapus-nav-collapse">
          <Nav className="hapus-nav-links ms-auto">
            <Nav.Link as={Link} to="/" className="hapus-nav-link">
              <FaHome className="hapus-nav-icon" /> Home
            </Nav.Link>
            <Nav.Link as={Link} to="/aboutus" className="hapus-nav-link">
              <FaInfoCircle className="hapus-nav-icon" /> About
            </Nav.Link>
            <Nav.Link as={Link} to="/courses" className="hapus-nav-link">
              <FaBook className="hapus-nav-icon" /> Courses
            </Nav.Link>
            <Nav.Link as={Link} to="/contactus" className="hapus-nav-link">
              <FaEnvelope className="hapus-nav-icon" /> Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/blogs" className="hapus-nav-link">
              <FaUserGraduate className="hapus-nav-icon" /> Blogs
            </Nav.Link>
            
            {!isStudentAuthenticated && !isInstituteAuthenticated && (
              <NavDropdown 
                title="Login/Register" 
                id="basic-nav-dropdown"
                className="hapus-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/student/auth" className="hapus-dropdown-item">
                  <FaUserGraduate className="hapus-nav-icon" /> Student
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/institute/auth" className="hapus-dropdown-item">
                  <FaUniversity className="hapus-nav-icon" /> Institute
                </NavDropdown.Item>
              </NavDropdown>
            )}

            {isStudentAuthenticated && (
              <>
                <Nav.Link as={Link} to="/student/dashboard" className="hapus-nav-link">
                  <FaTachometerAlt className="hapus-nav-icon" /> Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/student/profile" className="hapus-nav-link">
                  <FaUser className="hapus-nav-icon" /> Profile
                </Nav.Link>
                <Nav.Link onClick={handleLogout} className="hapus-nav-link hapus-logout-link">
                  <FaSignOutAlt className="hapus-nav-icon" /> Logout
                </Nav.Link>
              </>
            )}

            {isInstituteAuthenticated && (
              <>
                <Nav.Link as={Link} to="/institute/dashboard" className="hapus-nav-link">
                  <FaTachometerAlt className="hapus-nav-icon" /> Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/institute/courses" className="hapus-nav-link">
                  <FaBook className="hapus-nav-icon" /> My Courses
                </Nav.Link>
                <Nav.Link as={Link} to="/institute/manage-blogs" className="hapus-nav-link">
                  <FaBlog className="hapus-nav-icon" /> My Blogs
                </Nav.Link>
                <Nav.Link as={Link} to="/institute/profile" className="hapus-nav-link">
                  <FaUser className="hapus-nav-icon" /> My Profile
                </Nav.Link>
                <Nav.Link onClick={handleLogout} className="hapus-nav-link hapus-logout-link">
                  <FaSignOutAlt className="hapus-nav-icon" /> Logout
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;