import axios from 'axios';

const studentApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Add request interceptor to include auth token
studentApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle errors
studentApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/student/auth';
    }
    return Promise.reject(error);
  }
);

export default studentApi;

export const getStudentProfile = async () => {
  const response = await studentApi.get('/student/profile');
  return response.data;
};

export const updateStudentProfile = async (profile) => {
    const response = await studentApi.put('/student/profile', profile);
  return response.data;
};

export const getStudentDashboard = async () => {
  const response = await studentApi.get('/student/dashboard');
  return response.data;
};

export const checkEnrollment = async (courseId) => {
  const response = await studentApi.get(`/student/courses/${courseId}/check-enrollment`);
  return response.data;
};