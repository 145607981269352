import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const SkeletonLoader = () => {
  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Skeleton height={50} width={300} />
          <Skeleton height={20} width={200} />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Skeleton height={100} />
              <Skeleton count={3} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Skeleton height={30} width={200} />
              <Skeleton count={2} height={40} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={8} className="mb-4">
          <Skeleton height={30} width={200} />
          <Skeleton count={3} height={100} />
        </Col>
        <Col lg={4} className="mb-4">
          <Skeleton height={30} width={200} />
          <Skeleton height={200} />
        </Col>
      </Row>
    </Container>
  );
};

export default SkeletonLoader;
