import React from 'react';
import CustomNavbar from './components/CustomNavbar';
import { StudentAuthProvider } from './student/StudentAuthProvider';
import { InstituteAuthProvider } from './institute/InstituteAuthProvider';
import ErrorBoundary from './components/ErrorBoundary';

const AppWrapper = ({ children }) => {
  return (
    <ErrorBoundary>
      <StudentAuthProvider>
        <InstituteAuthProvider>
          <CustomNavbar />
          {children}
        </InstituteAuthProvider>
      </StudentAuthProvider>
    </ErrorBoundary>
  );
};

export default AppWrapper;