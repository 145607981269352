import React, { createContext, useContext, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';
import studentApi from './StudentApiService';

const StudentAuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true,
};

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

export function StudentAuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = Cookies.get('studentToken');
      if (token) {
        try {
          studentApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await studentApi.get('/student/profile');
          dispatch({ type: 'LOGIN', payload: response.data });
        } catch (error) {
          console.error('Error fetching user data:', error);
          Cookies.remove('studentToken');
          delete studentApi.defaults.headers.common['Authorization'];
          dispatch({ type: 'LOGOUT' });
        }
      } else {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    checkAuthStatus();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await studentApi.post('/student/login', { email, password });
      const { token, student } = response.data;
      Cookies.set('studentToken', token, { expires: 7 });
      studentApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      dispatch({ type: 'LOGIN', payload: student });
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const logout = () => {
    Cookies.remove('studentToken');
    delete studentApi.defaults.headers.common['Authorization'];
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <StudentAuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </StudentAuthContext.Provider>
  );
}

export const useStudentAuth = () => {
  const context = useContext(StudentAuthContext);
  if (context === undefined) {
    throw new Error('useStudentAuth must be used within a StudentAuthProvider');
  }
  return context;
};

export default StudentAuthProvider;